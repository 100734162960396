<template>
  <section id="hero-sec" class="hero-sec contact mb-0">
    <div class="container">
      <div class="row flex-lg-row-reverse align-items-center my-5">
        <div class="col-12">
          <h1 class="mb-3 text-center text-white fw-bold">Contact Us</h1>
          <p class="mb-5 text-center text-white w-lg-90 mx-lg-auto">
            Want to get in touch? We'd love to hear from you. Here's how you can reach us
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="v-contact my-5 p-0">
    <div class="container">
      <div class="row align-items-center mt-5 justify-content-center">
        <div class="col-md-6">
          <div class="app-title">
            <span>Get In Touch</span>
          </div>
          <div class="social-container mt-5">
            <div class="contact-ifo">
              <p>
                Sell, service, market, and connect to grow your business every
                day. Vendi helps you streamline and automate your distribution
                network, making the process more efficient and helps you in
                tracking the Goods Inventory.
              </p>
              <div class="mt-5 app-info">
                <div class="d-flex">
                  <h5 class="me-3">
                    <fa :icon="['fas', 'map-marker-alt']" />
                  </h5>
                  <p>
                    P-6 Mall 2 , 2nd Floor, Kohinoor 1, Faisalabad, Pakistan
                  </p>
                </div>
                <div class="d-flex">
                  <h5 class="me-3">
                    <fa :icon="['fas', 'phone-alt']" />
                  </h5>
                  <p>+923000880001</p>
                </div>
                <div class="d-flex">
                  <h5 class="me-3">
                    <fa :icon="['fas', 'envelope']" />
                  </h5>
                  <p>info@vendi.pk</p>
                </div>
              </div>
              <ul class="social-icons mt-3">
                <li>
                  <a href="#">
                    <fa class="i" :icon="['fab', 'instagram']" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <fa class="i" :icon="['fab', 'twitter']" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/Vendipk-107412725011866">
                    <fa class="i" :icon="['fab', 'facebook-f']" />
                  </a>
                </li>
              </ul>
              <!-- <button class="btn btn-primary hvr-icon-wobble-horizontal mt-3">
                    Find Location <fa class="hvr-icon" :icon="['fas', 'arrow-right']"
                      />
                  </button> -->
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="h-100">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.7313174680025!2d73.08786281462837!3d31.421527959161136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3922681f91ae44c1%3A0xb2002e32b62d6067!2s101%20Regency%20Rd%2C%20Faisalabad%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1634194381886!5m2!1sen!2s"
              width="100%" height="350px" style="border: 0" allowfullscreen="" loading="lazy"></iframe>

            <!-- <div class="app-form">
                  <div class="app-form-group">
                    <input class="app-form-control" placeholder="NAME" />
                  </div>
                  <div class="app-form-group">
                    <input class="app-form-control" placeholder="EMAIL" />
                  </div>
                  <div class="app-form-group">
                    <input class="app-form-control" placeholder="CONTACT NO" />
                  </div>
                  <div class="app-form-group message">
                    <input class="app-form-control" placeholder="MESSAGE" />
                  </div>
                  <div class="app-form-group buttons">
                    <button class="app-form-button">CANCEL</button>
                    <button class="app-form-button">SEND</button>
                  </div>
                </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.hero-sec.contact {
  min-height: 100%;
}

.v-contact {
  min-height: 100% !important;

  .app-info {
    h5 {
      color: #188f91;
    }

    p {
      color: #666;
    }
  }

  .social-icons {
    $timing: 265ms;
    $iconColor: #00b5f5;
    $accent: #002a8f;
    $bluefade: #0043e0;
    $gradient: #00b5f5;

    @mixin transformScale($size: 1) {
      transform: scale($size);
      -ms-transform: scale($size);
      -webkit-transform: scale($size);
    }

    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: 0.15em;
      position: relative;
      font-size: 1.2em;
    }

    .i {
      color: #fff;
      position: absolute;
      top: 13px;
      left: 13px;
      transition: all $timing ease-out;
    }

    a {
      display: inline-block;

      &:before {
        @include transformScale();
        content: " ";
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: block;
        background: linear-gradient(to right, #a1dd9a 0%, #24b2bb 100%);
        transition: all $timing ease-out;
      }

      &:hover:before {
        transform: scale(0);
        transition: all $timing ease-in;
      }

      &:hover .i {
        @include transformScale(1.5);
        color: $iconColor;
        background: linear-gradient(to right, #a1dd9a 0%, #24b2bb 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: all $timing ease-in;
      }
    }
  }

  .contct-info,
  .app-info {
    p {
      font-size: 15px;
    }
  }

  .screen {
    position: relative;
    background: #f0efef;
    border-radius: 15px;
  }

  .screen:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    bottom: 0;
    border-radius: 15px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    z-index: -1;
  }

  .screen-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #188f91;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .screen-header-left {
    margin-right: auto;
  }

  .screen-header-button {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 3px;
    border-radius: 8px;
    background: white;
  }

  .screen-header-button.close {
    background: rgba(var(--primary), 1) !important;
  }

  .screen-header-button.maximize {
    background: #e8e925;
  }

  .screen-header-button.minimize {
    background: #74c54f;
  }

  .screen-header-right {
    display: flex;
  }

  .screen-header-ellipsis {
    width: 3px;
    height: 3px;
    margin-left: 2px;
    border-radius: 8px;
    background: #999;
  }

  .screen-body {
    display: flex;
  }

  .screen-body-item {
    flex: 1;
    padding: 50px;
  }

  .screen-body-item.left {
    display: flex;
    flex-direction: column;
  }

  .app-title {
    display: flex;
    flex-direction: column;
    position: relative;
    color: rgba(var(--primary), 1) !important;
    font-size: 26px;
  }

  .app-title:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 30%;
    height: 4px;
    background: rgba(var(--primary), 1) !important;
  }

  .app-contact {
    margin-top: auto;
    font-size: 8px;
    color: #888;
  }

  .app-form-group {
    margin-bottom: 15px;
  }

  .app-form-group.message {
    margin-top: 40px;
  }

  .app-form-group.buttons {
    margin-bottom: 0;
    text-align: right;
  }

  .app-form-control {
    width: 100%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid rgb(38, 38, 38);
    color: rgb(0, 0, 0);
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
    transition: border-color 0.2s;
  }

  .app-form-control::placeholder {
    color: #666;
  }

  .app-form-control:focus {
    border-bottom-color: rgb(12, 12, 12);
  }

  .app-form-button {
    background: none;
    border: none;
    color: rgba(var(--primary), 1) !important;
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }

  .app-form-button:hover {
    color: #b9134f;
  }

  .credits {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: #ffa4bd;
    font-size: 16px;
    font-weight: normal;
  }

  .credits-link {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
  }

  .dribbble {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }

  @media screen and (max-width: 520px) {
    .screen-body {
      flex-direction: column;
    }

    .screen-body-item.left {
      margin-bottom: 30px;
    }

    .app-title {
      flex-direction: row;
    }

    .app-title span {
      margin-right: 12px;
    }

    .app-title:after {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    .screen-body {
      padding: 40px;
    }

    .screen-body-item {
      padding: 0;
    }
  }

  /* Icon Wobble Horizontal */
  @-webkit-keyframes hvr-icon-wobble-horizontal {
    16.65% {
      -webkit-transform: translateX(6px);
      transform: translateX(6px);
    }

    33.3% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }

    49.95% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }

    66.6% {
      -webkit-transform: translateX(-2px);
      transform: translateX(-2px);
    }

    83.25% {
      -webkit-transform: translateX(1px);
      transform: translateX(1px);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes hvr-icon-wobble-horizontal {
    16.65% {
      -webkit-transform: translateX(6px);
      transform: translateX(6px);
    }

    33.3% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }

    49.95% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }

    66.6% {
      -webkit-transform: translateX(-2px);
      transform: translateX(-2px);
    }

    83.25% {
      -webkit-transform: translateX(1px);
      transform: translateX(1px);
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  .hvr-icon-wobble-horizontal {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .hvr-icon-wobble-horizontal .hvr-icon {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .hvr-icon-wobble-horizontal:hover .hvr-icon,
  .hvr-icon-wobble-horizontal:focus .hvr-icon,
  .hvr-icon-wobble-horizontal:active .hvr-icon {
    -webkit-animation-name: hvr-icon-wobble-horizontal;
    animation-name: hvr-icon-wobble-horizontal;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
}</style>